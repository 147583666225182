<template>
  <div class="warranty contentWrapper">
    <h1>Warranty</h1>
    <div class="content">
      <h3>1 Year Limited Warranty</h3>
      <p>
        NaturalPoint warrants to the original consumer or other end user who
        purchased directly from NaturalPoint or through an Authorized dealer
        that this product, the NaturalPoint TrackIR (excluding software), is free from defects in
        materials and workmanship for a period of one year from the date of
        purchase. During the limited warranty period, an RMA request originating
        in the United States, or any product purchased from NaturalPoint
        directly and with a valid proof of purchase (in the form of product
        registration or packing slip / invoice) will be repaired or replaced at
        our option, without charge. During the limited warranty period, an RMA
        request originating outside of the United States, and not purchased
        directly from NaturalPoint, should be submitted to the Authorized Dealer
        from where the product was originally purchased, the Authorized Dealer
        will repair or replace the unit at their discretion, without charge.
      </p>
      <p>
        This warranty shall be voided if; the product is not purchased from NaturalPoint or an authorized dealer, modified, tampered with, misused, or subjected to abnormal working conditions. This warranty is voided if the serial number is removed or otherwise illegible. This warranty does not cover physical damage to the surface of this product. This warranty does not apply when the malfunction results from the use of this product in conjunction with accessories, other products, or peripheral equipment. Software is offered “AS IS” without warranty.
      </p>
      <p>
        Repair or replacement as provided under this warranty is the exclusive
        remedy of the purchaser. THIS WARRANTY IS IN LIEU OF ALL OTHER
        WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY IMPLIED WARRANTY OF
        FITNESS OR SUITABILITY FOR A PARTICULAR USE OR PURPOSE, AND NATURALPOINT
        INC. SHALL IN NO EVENT BE LIABLE TO PURCHASER FOR INDIRECT OR
        CONSEQUENTIAL DAMAGES OF ANY KIND OR CHARACTER. Some states do not allow
        the exclusion or limitation or incidental or consequential damages or
        allow limitations on how long an implied warranty lasts, so the above
        limitations and exclusions may not apply to you.
      </p>
      <p>
        ANY USE OF THE TRACKIR FOR PURPOSES OTHER THAN THOSE EXPRESSLY PROMOTED
        BY NATURALPOINT OR ANY DISASSEMBLY OR MODIFICATION OF THE TRACKIR
        HARDWARE OR SOFTWARE WILL VOID THIS AND ANY OTHER WARRANTY OR RETURN
        RIGHTS.
      </p>
    </div>
    <h1>Return Policy</h1>
    <div class="content">
        <p>If you need to return a product to us, please review our Limited Warranty to verify that you are eligible. Once you have verified your eligibility, you must use our <router-link to="/help">contact form</router-link> within 12 months of purchase to request a Return Merchandise Authorization (RMA) number. We will issue you an RMA valid for 15 days from the date of issue. All products returned must be accompanied by a valid RMA number. We are unable to accept any product returns after the expiration date of the RMA. RMAs cannot be extended or re-issued.</p>
        <p>Customers must contact support before any item can be shipped back to NaturalPoint. Support will provide RMA information for items to be returned. Do not send any packages without valid RMA numbers.</p>
        <p>Customers are responsible to cover any and all shipping costs for returning product to NaturalPoint. If you have purchased from NaturalPoint directly, please cut out the return portion on your Order Slip and include it with all returns.</p>
        <p>If you have any questions about the return process please use our <router-link to="/help">contact form</router-link>. Please provide the serial number of your product. Serial numbers can be located on the device, on its cable, or by checking within the software.</p>

    </div>
  </div>
  <!-- END warranty -->
</template>
<script>
export default {
  name: "Warranty"
};
</script>
<style lang="scss" scoped>
.warranty {
  text-align: center;

  h3 {
    margin-bottom: 0;
    color: #ffffff;
    font-weight: 700;
  }

  h3 ~ p {
    margin-top: 0px;
  }

  .content {
    text-align: left;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
    padding-bottom: 40px;
    margin-bottom: 80px;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0px;
    }
  }

  a {
    text-decoration: underline;
    color: #ffffff;
  }
}
</style>
